import React, { useCallback, useEffect } from 'react';
import {useDropzone} from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
});

export default function Image(props) {
  const classes = useStyles();

	function getPaneImage(id, dir, ext) {
		return props.mongoClient.callFunction("getPaneImage", ['discovrfortableau', `${dir}/${id}.${ext}`]).then(result => {
			return result;
		});
	}

	function uploadPaneImage(b64image, id, dir, ext) {
		return props.mongoClient.callFunction("uploadPaneImage", [b64image, 'discovrfortableau', `${dir}/${id}.${ext}`,`image/${ext}`]).then(result => {
			return result;
		});
	}

	useEffect(() => {
		if (props.mongoClient && props.value === '') {
			getPaneImage(props.content[0].label, props.content[0].url, props.content[0].ext)
				.then(result => {
					if (result) {
						props.update(`data:image/${props.content[0].ext};base64,${result.toString('base64')}`)
					}
				});
		}/*eslint-disable*/
	}, []);/*eslint-enable*/

	const onDrop = useCallback((acceptedFiles) => {
		console.log(acceptedFiles);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
				const b64 = btoa(reader.result);
				if (props.mongoClient) {
					uploadPaneImage(b64, props.content[0].label, props.content[0].url, props.content[0].ext);
					props.update(`data:image/${props.content[0].ext};base64,${b64}`);
				}
      }
      reader.readAsBinaryString(file)
    })
		/* eslint-disable */
  }, [props.mongoClient]);/* eslint-enable */

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/*', multiple: false});

	if (props.edit) {
		return (
			<div {...getRootProps()}>
	      <input {...getInputProps()} />
				{props.value !== '' ?
					(<div style={{
							height: 70,
							width: '100%',
							backgroundImage: `url(${props.value})`,
							backgroundSize: 'contain',
	    				backgroundRepeat: 'no-repeat'}} />)
					:
						(<p>Drag 'n' drop here, or click to select an image</p>)
					}

	    </div>
		)
	} else if (props.hide) {
		return ( null );
	} else {
		return (
	    <div className={classes.root} >
				{props.value !== '' ?
	      (<div style={{
						height: 70,
						width: '100%',
						backgroundImage: `url(${props.value})`,
						backgroundSize: 'contain',
    				backgroundRepeat: 'no-repeat'}} />) : (null)}
	    </div>
	  );
	}

}
