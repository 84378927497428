import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

export default function LinkList(props) {

	const [hover, setHover] = useState(null);

	const sendTelemetryGql = gql`
	mutation Telemetry($action: String, $item: String, $pane_id: ObjectId) {
	  insertOneTelemetry (
	    data:{
				action: $action,
				item: $item,
				pane_id: {link: $pane_id }
				timestamp: 0
			})
			{
				action
	  }
	}`;

	function updateLink(idx, label, url, description) {
		const links = [...props.content];
		links[idx].label = label || '';
		links[idx].url = url || '';
		links[idx].description = description || '';
		props.update(links);
	}

	function insertLink(idx) {
		const links = [...props.content];
		const newLink = {label: '', url: '', description: ''};
		if (idx === links.length) {
			links.push(newLink)
		} else {
			links.splice(idx, 0, newLink);
		}
		props.update(links);
	}

	function deleteLink(idx) {
		const links = [...props.content];
		links.splice(idx, 1);
		props.update(links);
	}

	const btnStyle = {
		marginTop: 20
	}

	const [
    sendTelemetry
  ] = useMutation(sendTelemetryGql, {
		onError: (error) => {
			console.error('[Links.js] GraphQL Error', error);
		}
	});

	return props.content.map((link, idx) => {
		if (props.edit) {
			return (
				<Grid container spacing={1} key={idx}>
					<Grid item xs={1}>
						<IconButton aria-label="insert" style={btnStyle} size="small" onClick={() => insertLink(idx + 1)}>
							<AddIcon fontSize="inherit" />
						</IconButton>
						<IconButton aria-label="delete" style={btnStyle} size="small" onClick={() => deleteLink(idx)}>
	          	<DeleteIcon fontSize="inherit" />
	        	</IconButton>
					</Grid>
					<Grid item xs={3}>
						<TextField
							label="Label"
							value={link.label ? link.label : ''}
							onChange={(e) => {
								updateLink(idx, e.target.value, link.url, link.description)
							}}/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							label="URL"
							value={link.url ? link.url : ''}
							onChange={(e) => {
								updateLink(idx, link.label, e.target.value, link.description)
							}}/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							label="Description"
							value={link.description ? link.description : ''}
							onChange={(e) => {
								updateLink(idx, link.label, link.url, e.target.value)
							}}/>
					</Grid>
				</Grid>
			)
		}
	  return (
			<Grid container spacing={1} key={idx}>
				<Grid item xs={12}>
	     		<Link href={link.url ? link.url : ''} target="_blank" onMouseEnter={() => setHover(idx)} onMouseLeave={() => setHover(null)} onClick={() => {
							console.log('Pane', props.pane);
							sendTelemetry({variables: {
								action: 'click',
								item: link.url,
								pane_id: props.pane
							}});
						}}>{link.label ? link.label : ''}</Link>
					<Collapse in={idx === hover && link.description !== ''}>
						<Typography variant="caption" display="block" gutterBottom style={{paddingLeft: 10}}>
			       {link.description}
			      </Typography>
					</Collapse>
				</Grid>
			</Grid>
	  );
	});

}
