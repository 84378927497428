import React from "react";
import { paneCol } from "../../../Stitch";
import ObjectID from "bson-objectid";

const paneReducer = (state, { type, payload }) => {
  switch (type) {
    case "setPanes": {
      return {
        ...state,
        hasHadPanes: payload.panes.length > 0,
        panes: payload.panes || [],
      };
    }
    case "updatePane": {
			console.log('[Panes.js] payload.p', payload.p);
			console.log('[Panes.js] panes', state.panes);
			const idx = state.panes.findIndex((pane) => {
				return ObjectID(pane._id).toHexString() === ObjectID(payload.p._id).toHexString();
			})
			const updatedPanes = [...state.panes];
			updatedPanes[idx] = payload.p;
			console.log('[Panes.js] updatedPanes', updatedPanes);

      return {
        ...state,
        hasHadPanes: true,
        panes: [...updatedPanes],
      };
    }
    default: {
      console.error(`Received invalid todo action type: ${type}`);
    }
  }
};

export function usePaneItems(orgId) {

  const [state, dispatch] = React.useReducer(paneReducer, { panes: [] });

  const loadPanes = async () => {
    const panes = await paneCol.find({}, { limit: 1000 }).asArray();
    dispatch({ type: "setPanes", payload: { panes } });
  };
  const updatePane = async p => {
    const pane = { p };
    const result = await paneCol.updateOne(
      { _id: p._id },
      { $set: {
				paneTitle: p.paneTitle,
				paneDescription: p.paneDescription,
				modules: p.modules
			 } },
      { returnNewDocument: true },
    )
		console.log('[Panes.js] updatePane result', result);
    dispatch({ type: "updatePane", payload: { ...pane } });
  };

  React.useEffect(() => {
    loadPanes();
  }, []);
  return {
    panes: state.panes,
    hasHadPanes: state.hasHadPanes,
    paneActions: {
      updatePane
    },
  };
}
