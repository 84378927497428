import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Add';
import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import SectionTitle from './SectionTitle/SectionTitle';
import LinkList from './LinkList/LinkList';
import Divider from './Divider/Divider';
import TextBlock from './TextBlock/TextBlock';
import Image from './Image/Image';

const useStyles = makeStyles({
  root: {
		maxWidth: 260
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
	module: {
		width: '100%'
	},
	moduleEdit: {
		marginTop: 20,
		marginBottom: 10
	},
	primary : {
		margin: 10,
		backgroundColor : '#3896d352',
		boxShadow: 'none'
	}
});

export default function RenderPane(props) {
  const classes = useStyles();
	const [hover, setHover] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, type) => {
		console.log('[EditPane.js] handleClose', anchorEl.dataset.idx, type);
		if (type) {
			props.addModule(parseInt(anchorEl.dataset.idx) + 1, type);
		}
    setAnchorEl(null);
  };

	function renderAddSectionBtn(idx, edit) {
		return (
			<Collapse in={hover === idx}>
				<Grid item xs={12}>
					<div style={{marginLeft: 'auto', marginRight: 'auto', display: 'flex', width: 250}}>
						<Fab
	          variant="extended"
	          size="small"
	          color="primary"
	          aria-label="add"
						className={classes.primary}
						onClick={handleClick}
						data-idx={idx}
						style={{height: 25}}
	        >
	          <AddIcon fontSize="inherit" />
	          <span style={{fontSize: '0.75rem'}}>Add Section</span>
	        </Fab>
					<Fab
						variant="extended"
						size="small"
						color="secondary"
						aria-label="delete"
						className={classes.primary}
						onClick={() => props.removeModule(idx)}
						data-idx={idx}
						style={{height: 25}}
					>
						<DeleteIcon fontSize="inherit" />
						<span style={{fontSize: '0.75rem'}}>Remove</span>
					</Fab>
					</div>
				</Grid>
			</Collapse>
		)
	}
	if (props.pane && props.pane.modules) {
		return (
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Image
						edit={props.edit}
						content={[{label: props.pane._id, url: 'pane-logos', ext: 'png'}]}
						update={(image) => props.updatePaneImage(image)}
						value={props.paneImage}
						hide={false}
						label="Pane Logo"
						mongoClient={props.mongoClient}
					/>
				</Grid>
				<div className={classes.module} onMouseEnter={() => setHover(-1)} onMouseLeave={() => setHover(null)}>
					<Grid item xs={12} className={classes.moduleEdit}>
						<TextBlock
							edit={props.edit}
							value={props.pane.paneDescription}
							update={(value) => props.updateDescription(value)}
							hide={false}
							label="Pane Desctiption"
						/>
					</Grid>
					{props.edit ? renderAddSectionBtn(-1) : null}
				</div>
				{props.pane.modules.map((module, idx) => {
				if (module.type === 'sectionTitle') {
					return (
						<div key={idx} onMouseEnter={() => setHover(idx)} onMouseLeave={() => setHover(null)} className={classes.module}>
							<Grid item xs={12} className={classes.moduleEdit}>
								<SectionTitle
									edit={props.edit}
									update={(module) => props.updateModule(idx, module)}
									content={module.content}
									/>
							</Grid>
							{props.edit ? renderAddSectionBtn(idx) : null}
						</div>
					)
				} else if (module.type === 'linkList') {
					return (
						<div key={idx} onMouseEnter={() => setHover(idx)} onMouseLeave={() => setHover(null)} className={classes.module}>
							<Grid item xs={12} style={{marginBottom: 20}}>
								<LinkList
									pane={props.pane._id}
									edit={props.edit}
									update={(module) => props.updateModule(idx, module)}
									content={module.content}
									/>
							</Grid>
							{props.edit ? renderAddSectionBtn(idx) : null}
						</div>
					)
				} else if (module.type === 'divider') {
					return (
						<div key={idx} onMouseEnter={() => setHover(idx)} onMouseLeave={() => setHover(null)} className={classes.module}>
							<Grid item xs={12}>
								<Divider
									edit={props.edit}
									update={(module) => props.updateModule(idx, module)}
									content={module.content}
									/>
							</Grid>
							{props.edit ? renderAddSectionBtn(idx) : null}
						</div>
					)
				} else if (module.type === 'textBlock') {
					return (
						<div key={idx} onMouseEnter={() => setHover(idx)} onMouseLeave={() => setHover(null)} className={classes.module}>
							<Grid item xs={12}>
								<TextBlock
									edit={props.edit}
									value={module.content}
									update={(module) => props.updateModule(idx, module)}
									label="Text Block"
								/>
							</Grid>
							{props.edit ? renderAddSectionBtn(idx) : null}
						</div>
					)
				}
				return (
					<Grid item xs={12} key={idx}>
						Invalid Module
					</Grid>
				)
			})}
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => handleClose()}
			>
				<MenuItem onClick={(e) => handleClose(e, 'sectionTitle')}>Section Title</MenuItem>
				<MenuItem onClick={(e) => handleClose(e, 'textBlock')}>Text Block</MenuItem>
				<MenuItem onClick={(e) => handleClose(e, 'linkList')}>Link List</MenuItem>
				<MenuItem onClick={(e) => handleClose(e, 'divider')}>Divider</MenuItem>
			</Menu>
			</Grid>

		)
	}
	return (
		<div>
			<Skeleton variant="rect" width={"90%"} height={40} />
			<Skeleton variant="rect" width={"90%"} height={20} />
			<Box pt={0.75}>
			 <Skeleton width="90%" />
			 <Skeleton width="80%" />
			 <Skeleton width="80%" />
			 <Skeleton width="80%" />
			 <Skeleton width="80%" />
		 </Box>
		 <Box pt={0.75}>
			<Skeleton width="90%" />
			<Skeleton width="80%" />
			<Skeleton width="80%" />
			<Skeleton width="80%" />
			<Skeleton width="80%" />
		</Box>
	 </div>
 )
}
