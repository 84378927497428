import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import OrganisationUsers from './OrganisationUsers/OrganisationUsers';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

export default function Organisation(props) {
  const classes = useStyles();

  return (
    <div>
			<Typography variant="h5" component="h2">
				{props.userData && props.userData.organisation && props.userData.organisation.organisationName ? props.userData.organisation.organisationName : (
				<Box pt={1}>
					<Skeleton width="500px" />
				</Box>)}
			</Typography>
			<OrganisationUsers />
		</div>
  );
}
