import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import Organisation from './Organisation/Organisation';
import Panes from './Panes/Panes';
import Links from './Links/Links';

const getUserData = gql`
	query {
		userMeta {
			_id,
			firstname,
			surname,
			email,
			userLevel,
			organisation: organisation_id {
				_id
				organisationName
				primaryDomain
			}
		}
	}`;

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Admin(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

	const { data } = useQuery(getUserData);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
				<Link href={"/admin/panes"}>
					<ListItem button>
						<ListItemIcon><ChromeReaderModeIcon /></ListItemIcon>
						<ListItemText primary="Panes" />
					</ListItem>
				</Link>
				<Link href={"/admin/links"}>
					<ListItem button>
						<ListItemIcon><LinkIcon /></ListItemIcon>
						<ListItemText primary="Links" />
					</ListItem>
				</Link>
				<Link href={"/admin/organisation"}>
					<ListItem button>
						<ListItemIcon><PeopleIcon /></ListItemIcon>
						<ListItemText primary="Organisation" />
					</ListItem>
				</Link>
				<Link href={"/admin/profile"}>
					<ListItem button>
						<ListItemIcon><PersonIcon /></ListItemIcon>
						<ListItemText primary="Profile" />
					</ListItem>
				</Link>
      </List>
    </div>
  );

	function renderOrganisation() {
		return (
			<Organisation mongoClient={props.mongoClient} userData={data ? data.userMeta : {}}/>
		)
	}

	function renderPanes() {
		return (
			<Panes mongoClient={props.mongoClient} userData={data ? data.userMeta : {}}/>
		)
	}

	function renderLinks() {
		return (
			<Links mongoClient={props.mongoClient} userData={data ? data.userMeta : {}}/>
		)
	}

  return (
		<BrowserRouter>
	    <div className={classes.root}>
	      <CssBaseline />
	      <AppBar position="fixed" className={classes.appBar}>
	        <Toolbar>
	          <IconButton
	            color="inherit"
	            aria-label="open drawer"
	            edge="start"
	            onClick={handleDrawerToggle}
	            className={classes.menuButton}
	          >
	            <MenuIcon />
	          </IconButton>
	          <Typography variant="h6" noWrap style={{flexGrow: 1}}>
	            Discovr for Tableau
	          </Typography>
						<Button color="inherit" onClick={props.logout}>Logout</Button>
	        </Toolbar>
	      </AppBar>
	      <nav className={classes.drawer} aria-label="mailbox folders">
	        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
	        <Hidden smUp implementation="css">
	          <Drawer
	            container={container}
	            variant="temporary"
	            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
	            open={mobileOpen}
	            onClose={handleDrawerToggle}
	            classes={{
	              paper: classes.drawerPaper,
	            }}
	            ModalProps={{
	              keepMounted: true, // Better open performance on mobile.
	            }}
	          >
	            {drawer}
	          </Drawer>
	        </Hidden>
	        <Hidden xsDown implementation="css">
	          <Drawer
	            classes={{
	              paper: classes.drawerPaper,
	            }}
	            variant="permanent"
	            open
	          >
	            {drawer}
	          </Drawer>
	        </Hidden>
	      </nav>
	      <main className={classes.content}>
	        <div className={classes.toolbar} />
	        <Route path="/admin/organisation" render={renderOrganisation} exact />
					<Route path="/admin/panes" render={renderPanes} />
					<Route path="/admin/links" render={renderLinks} exact/>
					<Route path="/admin" render={renderPanes} exact />
	      </main>
	    </div>
		</BrowserRouter>
  );
}

Admin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default Admin;
