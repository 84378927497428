import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import RenderLink from './RenderLink/RenderLink';


export default function Link(props) {
	const { mongoClient, isLoggedIn, login, keyLogin } = props;
	const [loggedIn, setLoggedIn] = useState(false);
	const { id, key } = useParams();

	useEffect(() => {
		if (mongoClient && !isLoggedIn) {
			login(key);
		}
	}, [key, mongoClient, isLoggedIn, login]);

	useEffect(() => {
		setLoggedIn(isLoggedIn);
	}, [isLoggedIn, keyLogin]);

	if (isLoggedIn) {
		return (
			<RenderLink id={id} mongoClient={mongoClient} loggedIn={loggedIn}/>
		)
	} else {
		return (
			null
		)
	}

}
