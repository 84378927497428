import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Login from './Login/Login';

import Discovr from './discovr@1x.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
		fontWeight: '400',
		marginLeft: 20,
		fontSize: '1rem'
  },
}));

export default function LandingPage(props) {
	const classes = useStyles();

	function renderLogo() {
		return (
			<Container style={{width: 380, marginLeft: 'auto', marginRight: 'auto', paddingTop: 100}}>
				<img src={Discovr} alt={'Discovr for Tableau'} />
			</Container>
		)
	}

	function renderLogin() {
		return (
			<Login login={props.login} isLoggedIn={props.isLoggedIn} />
		)
	}

  return (
		<BrowserRouter>
			<React.Fragment>
				<CssBaseline />
					<div className={classes.root}>
					<AppBar position="static">
						<Toolbar>
							<Typography variant="h6" className={classes.header}>
								Home
							</Typography>
							<Typography variant="h6" className={classes.header}>
								Blog
							</Typography>
							<Typography variant="h6" className={classes.header}>
								Features
							</Typography>
							<Typography variant="h6" className={classes.header} style={{flexGrow: 1}}>
								Pricing
							</Typography>
							<Button color="inherit" href="/login">Login</Button>
						</Toolbar>
					</AppBar>
				</div>
				<Grid container spacing={0}>
					<Grid item xs={12} style={{background: 'url(./img/laptopSplash.png)  no-repeat center center fixed', height: '80vh', maxHeight: 670, width: '100vw', backgroundSize: 'cover', maxWidth: 'unset'}}>
						<Paper elevation={3} square={false} style={{position: 'relative', top: 300, height: 400, width: '40%', marginLeft: 'auto', marginRight: 'auto', minWidth: 440}}>
							<Route path="/" render={renderLogo} exact />
							<Route path="/login" render={renderLogin} exact />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={0}>
							Hello
						</Paper>
					</Grid>
				</Grid>
			</React.Fragment>
		</BrowserRouter>
  );
}
