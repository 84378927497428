import React from "react";
import { paneCol, linkCol } from "../../../Stitch";
import ObjectID from "bson-objectid";

const paneReducer = (state, { type, payload }) => {
  switch (type) {
    case "setPane": {
      return {
        ...state,
        pane: payload.pane || {},
      };
    }
    default: {
      console.error(`Received invalid todo action type: ${type}`);
    }
  }
};

export function usePaneItems(paneId) {

  const [state, dispatch] = React.useReducer(paneReducer, { pane: [] });

  const loadPane = async () => {
		const link = await linkCol.findOne({_id: ObjectID(paneId)});
    const pane = await paneCol.findOne({_id: link.pane_id});
    dispatch({ type: "setPane", payload: { pane } });
  };

  React.useEffect(() => {
    loadPane();
		/*eslint-disable*/
  }, []);/*eslint-enable*/

  return {
    pane: state.pane
  };
}
