import React from 'react';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
		margin: 10
  },
});

export default function SectionTitle(props) {
  const classes = useStyles();
	if (props.edit) {
		return (
			<TextField
				style={{marginTop: 20, width: 120}}
				label="Divider Width"
				type="number"
				variant="outlined"
				value={props.content ? props.content : '100'}
				onChange={(e) => {
					props.update(e.target.value);
				}}
				InputProps={{
            startAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}/>
		)
	}
  return (
    <div className={classes.root} style={{width : props.content ? props.content + '%' : '100%', marginLeft: 'auto', marginRight: 'auto'}}>
      <Divider />
    </div>
  );
}
