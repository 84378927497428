import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { UserPasswordCredential, FunctionCredential } from 'mongodb-stitch-browser-sdk';
import { ApolloClient  } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';
import { setContext } from "apollo-link-context";
import LandingPage from '../components/LandingPage/LandingPage';
import Admin from '../components/Admin/Admin';
import Link from '../components/Link/Link';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { app } from '../Stitch';

class App extends React.Component {
	state = {
			currentUserId: undefined,
      client: undefined,
			keyLogin: false
	  };

	theme = createMuiTheme({
		palette: {
			primary: {
	      // light: will be calculated from palette.primary.main,
	      main: '#3896d3',
	      // dark: will be calculated from palette.primary.main,
	      // contrastText: will be calculated to contrast with palette.primary.main
	    },
	    secondary: {
	      main: '#f16814',
	      // dark: will be calculated from palette.secondary.main,
	      contrastText: '#fff',
	    },
			// Used by `getContrastText()` to maximize the contrast between
	    // the background and the text.
	    contrastThreshold: 3,
	    // Used by the functions below to shift a color's luminance by approximately
	    // two indexes within its tonal palette.
	    // E.g., shift from Red 500 to Red 300 or Red 700.
	    tonalOffset: 0.2,
	  },
		typography: {
	    fontFamily: [
	      '"Benton Sans SemiDemi"',
	      'Roboto',
	      'sans-serif',
	      '"Apple Color Emoji"',
	      '"Segoe UI Emoji"',
	      '"Segoe UI Symbol"',
	    ].join(','),
			fontStyle: 'normal',
			fontSize: 14
		},
	});

	httpLink = createHttpLink({
	  uri: 'https://stitch.mongodb.com/api/client/v2.0/app/discovr-dev-rajgj/graphql',
		credentials: 'include'
	});

	authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	let authorization = "";
	if (this.state.client && this.state.client.auth && this.state.client.auth.authInfo) {
		authorization = `Bearer ${this.state.client.auth.authInfo.accessToken}`;
	}
		return {
	    headers: {
	      ...headers,
	      authorization ,
	    }
	  }
	});

	client = new ApolloClient({
			link: this.authLink.concat(this.httpLink),
			cache: new InMemoryCache()
		});

  componentDidMount () {
		// const client = Stitch.initializeDefaultAppClient('discovr-dev-rajgj');
		this.setState({ client : app });
		if(app.auth.isLoggedIn) {
			this.setState({ currentUserId: app.auth.user.id })
		}
  }

	handleLogin = (username, password) => {
		const client = this.state.client;
		client.auth.loginWithCredential(new UserPasswordCredential(username, password)).then(user => {
		  console.log(`logged in as user ${user.id}`, user);
			window.location.href="/admin/panes";
		});
	}

	handleLoginWithKey = (apiKey) => {
		const client = this.state.client;
		client.auth.loginWithCredential(new FunctionCredential({ key: apiKey })).then(orgId => {
			this.setState({keyLogin : true });
		  console.log(`logged in with API Key`);
		});
	}

	handleLogout = (username, password) => {
		const client = this.state.client;
		client.auth.logout().then(() => {
			window.location.href="/login";
		});
	}

	renderLandingPage = () => {
		return (
      <LandingPage login={this.handleLogin} isLoggedIn={this.state.client && this.state.client.auth && this.state.client.auth.user ? this.state.client.auth.user.isLoggedIn : false}/>
    )
	}

	renderAdminPage = () => {
		return (
      <Admin
				isLoggedIn={this.state.client && this.state.client.auth && this.state.client.auth.user ? this.state.client.auth.user.isLoggedIn : false}
				mongoClient={this.state.client} logout={this.handleLogout}
				/>
    )
	}

  render() {;

    return (
			<ApolloProvider client={this.client}>
	      <BrowserRouter>
					<ThemeProvider theme={this.theme}>
		        <main>
							<Route path="/link/:key/:id" children={<Link mongoClient={this.state.client} login={this.handleLoginWithKey} isLoggedIn={this.state.client && this.state.client.auth && this.state.client.auth.user ? this.state.client.auth.user.isLoggedIn : false} apiKeyLogin={this.state.keyLogin}/>} exact/>
							<Route path="/admin" render={this.renderAdminPage} exact/>
							<Route path="/admin/*" render={this.renderAdminPage} exact/>
							<Route path="/login" render={this.renderLandingPage} exact/>
							<Route path="/" render={this.renderLandingPage} exact/>
		        </main>
					</ThemeProvider>
				</BrowserRouter>
			</ApolloProvider>
    );
  }
}

export default App;
