import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default function TextBlock(props) {
	if (props.edit) {
		return (
			<TextField
				label={props.label}
				variant="outlined"
				multiline
        rows="4"
				style={{width: '80%', marginBottom: 10}}
				value={props.value ? props.value : ''}
				onChange={(e) => props.update(e.target.value)}
				/>
		)
	} else if (props.hide) {
		return (null)
	}
  return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
	      <Typography variant="body2" style={{marginTop: 10}}gutterBottom>
	        {props.value}
	      </Typography>
			</Grid>
    </Grid>
  );
}
