import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
	title : {
		color: 'rgb(78, 78, 78)'
	}
});

export default function SectionTitle(props) {
  const classes = useStyles();
	if (props.edit) {
		return (
			<TextField
				label="Section Title"
				variant="outlined"
				style={{width: '80%'}}
				value={props.content ? props.content : ''}
				onChange={(e) => props.update(e.target.value)}
				/>
		)
	}
  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title} gutterBottom>
        {props.content}
      </Typography>
    </div>
  );
}
