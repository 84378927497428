import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";
import { app } from "./app";

// TODO: Initialize a MongoDB Service Client
const mongoClient = app.getServiceClient(
  RemoteMongoClient.factory,
  "discovr-dev"
);

// TODO: Instantiate a collection handle for todo.items
// const moduleCol = mongoClient.db("discovr-dev").collection("module");
const paneCol = mongoClient.db("discovr-dev").collection("pane");
const linkCol = mongoClient.db("discovr-dev").collection("link");

export { linkCol, paneCol };
