import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ObjectID from "bson-objectid";

import RenderPane from '../RenderPane/RenderPane';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
	module: {
		width: '100%'
	}
});

const previewPaneStyle = {
	backgroundColor: 'rgb(235, 235, 235)',
	width: 275,
	maxWidth: 275,
	padding: 20,
	border: '1px solid rgb(200, 200, 200)',
	height: '80vh'
}

export default function EditPane(props) {
	const {mongoClient, panes, paneActions} = props;
  const classes = useStyles();

	const [paneImage, setPaneImage] = useState('');
	const [pane, setPane] = useState({});

	const { id } = useParams();

	useEffect(() => {
		if (panes.length > 0) {
			const paneIdx = panes.findIndex((pane) => {
				return ObjectID(pane._id).toHexString() === id;
			})
			setPane({...panes[paneIdx]});
		}/* eslint-disable */
	}, [panes])/* eslint-enable */

	function handleUpdatePaneDescription(value) {
		setPane({...pane, paneDescription: value});
	}

	function handleShowPaneDescription(value) {
		setPane({...pane, showDescription: value});
	}

	function handleShowPaneTitle(value) {
		setPane({...pane, showTitle: value});
	}

	function handleUpdateModule(idx, module) {
		const newModules = [...pane.modules];
		newModules[idx].content = module;
		setPane({...pane, modules: newModules});
	}

	const simpleSections = ['textBlock', 'sectionTitle', 'divider'];

	function handleAddModule(idx, type) {
		const newModules = [...pane.modules];
		let newModule = {};
		if ( simpleSections.indexOf(type) > -1 ) {
			newModule = {...newModule, type, content: ''};
		} else {
			newModule = {...newModule, type, content: [{}]};
		}
		if (idx === newModules.length) {
			newModules.push(newModule)
		} else {
			newModules.splice(idx, 0, newModule);
		}
		setPane({...pane, modules: newModules});
	}

	function handleRemoveModule(idx) {
		let newModules = [];
		if (idx === 0) {
      newModules = newModules.concat(pane.modules.slice(1));
    } else if (idx === pane.modules.length - 1) {
      newModules = newModules.concat(pane.modules.slice(0, -1));
    } else if (idx > 0) {
      newModules = newModules.concat(
        pane.modules.slice(0, idx),
        pane.modules.slice(idx + 1),
      );
    }
		setPane({...pane, modules: newModules});
	}

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {pane && pane.paneTitle ? pane.paneTitle : (
					<Box pt={1}>
						<Skeleton width="500px" />
					</Box>)}
        </Typography>
				<Grid container spacing={3}>
	        <Grid item xs={12} sm={9}>
	         <RenderPane
						 pane={pane}
						 edit={true}
						 updateModule={handleUpdateModule}
						 addModule={handleAddModule}
						 removeModule={handleRemoveModule}
						 updateDescription={handleUpdatePaneDescription}
						 showDescription={handleShowPaneDescription}
						 showTitle={handleShowPaneTitle}
						 paneImage={paneImage}
						 updatePaneImage={(image) => setPaneImage(image)}
						 mongoClient={mongoClient}/>
	        </Grid>
					<Grid item xs={12} sm={3}>
	         Tableau Desktop Preview
					 <div style={previewPaneStyle}>
					 	<RenderPane
							pane={pane}
							edit={false}
							mongoClient={mongoClient}
							paneImage={paneImage}
							updatePaneImage={(image) => setPaneImage(image)}/>
					 </div>
	        </Grid>
				</Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => paneActions.updatePane(pane)}>Save</Button>
      </CardActions>
    </Card>
  );
}
