import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Link from '@material-ui/core/Link';
import ResetApi from './ResetApi/ResetApi';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';

const headCells = [
  { id: 'pane', numeric: false, disablePadding: true, label: 'Pane' },
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'department', numeric: false, disablePadding: true, label: 'Department' },
  { id: 'location', numeric: false, disablePadding: true, label: 'Location' },
	{ id: 'link', numeric: false, disablePadding: true, label: 'Link' },
	{ id: 'actions', numeric: false, disablePadding: true, label: '' }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, addNewLink, userData, resetApi, mongoClient } = props;

	function renderApiReset (userData) {
		return	(
			<Button size="small" variant="outlined" color="primary" style={{width: 150, marginLeft: 20}} onClick={resetApi}>
				<AddIcon />Reset API Key
			</Button>
		)
	}

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle">
          Links
        </Typography>
      )}
			<Button size="small" variant="outlined" color="primary" style={{width: 150, marginLeft: 20}} onClick={addNewLink}>
        <AddIcon />Add New
      </Button>
			{renderApiReset(userData)}
			<div className={classes.title}></div>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1200,
  },
  tableWrapper: {
    overflowX: 'auto'
  },
	tableCell :{
		minWidth: 170
	},
	inputField : {
		width: 170,
		maxWidth: 170
	},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function Links(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
	const [editing, setEditing] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
	const [rows,setRows] = useState([]);
	const [panes,setPanes] = useState([]);
	const [deleteTooltip,setDeleteTooltip] = useState(InputLabel);
	const [openApiReset, setOpenApiReset] = React.useState(false);

	const getLinks = gql`
		query {
			links (sortBy: ${orderBy.toUpperCase()}_${order.toUpperCase()}) {
				_id
				name
				location
				department
				pane: pane_id {
					_id
					paneTitle
				}
			}
			organisation {
				apiKeys {
					key
					expires
				}
			}
			panes (sortBy: PANETITLE_ASC){
				_id
				paneTitle
			}
		}`;

	const updateLink = gql`
	mutation Link($_id: ObjectId, $name: String, $location: String, $department: String, $pane_id: ObjectId) {
	  updateOneLink (
	    query : {_id: $_id},
	    set:{
				name: $name,
				location: $location,
				department: $department,
				pane_id: {link: $pane_id }
			})
			{
	    _id
	    name
	    location
	    department
	    pane:pane_id {
	      _id
	      paneTitle
	    }
	  }
	}`;

	const createNewLink = gql`
	mutation Link($organisation_id: ObjectId, $name: String, $location: String, $department: String, $pane_id: ObjectId) {
	  insertOneLink (
	    data:{
				name: $name,
				location: $location,
				department: $department,
				pane_id: {link: $pane_id },
				organisation_id: {link: $organisation_id }
			})
			{
	    _id
	    name
	    location
	    department
	    pane:pane_id {
	      _id
	      paneTitle
	    }
	  }
	}`;

	const deleteOneLink = gql`
	mutation Link($_id: ObjectId) {
		deleteOneLink (query:{_id:$_id})
  {
    _id
  }
	}`;

	const { data, loading, error, refetch } = useQuery(getLinks);

	useEffect(() => {
		if (!loading && data && data.links) {
			setRows(data.links);
			setPanes(data.panes);
		}
	}, [loading, data])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

	const handleEditToggle = (event, id) => {
    const selectedIndex = editing.indexOf(id);
    let newEditing = [];

    if (selectedIndex === -1) {
      newEditing = newEditing.concat(editing, id);
    } else if (selectedIndex === 0) {
      newEditing = newEditing.concat(editing.slice(1));
    } else if (selectedIndex === editing.length - 1) {
      newEditing = newEditing.concat(editing.slice(0, -1));
    } else if (selectedIndex > 0) {
      newEditing = newEditing.concat(
        editing.slice(0, selectedIndex),
        editing.slice(selectedIndex + 1),
      );
    }

    setEditing(newEditing);
  };

	const handleEditLink = (id, name, value) => {
		const rowIdx = rows.findIndex((row) => {
			return row._id === id;
		});
		const newRows = [...rows];
		const row = newRows[rowIdx];
		if (name === 'pane_id') {
			row.pane._id = value;
		} else {
			row[name] = value;
		}
		newRows[rowIdx] = row;
		setRows(newRows);
	}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

	const handleAddNewLink = () => {
		const newRow = {
			_id: "new" + rows.length,
			name: "",
			location: "",
			department: "",
			pane: {}}
		const newRows = [newRow, ...rows];
		setRows(newRows);
		handleEditToggle(null, "new" + rows.length);
	}

	const [
    saveLink,
		{ loading: saveLoading }
  ] = useMutation(updateLink, {
		onError: (error) => {
			console.error('[Links.js] GraphQL Error', error);
		}
	});

	const [
    newLink,
		{ loading: newLoading }
  ] = useMutation(createNewLink, {
		onError: (error) => {
			console.error('[Links.js] GraphQL Error', error);
		}
	});

	const [
    deleteLink,
		{ loading: deleteLoading }
  ] = useMutation(deleteOneLink, {
		onError: (error) => {
			console.error('[Links.js] GraphQL Error', error);
		}
	});

	const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

	function generateLink(id, key, shorten) {
		let link = '';
		if (id && key && id.length > 10) {
			link = 'https://discovr.it/link/' + key + '/' + id;
		}
		if (shorten) {
			return link.substring(0,40) + '...';
		}
		return  link
	}

	const handleClickReset = () => {
    setOpenApiReset(!openApiReset);
  };

  const isSelected = id => selected.indexOf(id) !== -1;
	const isEditing = id => editing.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
			<ResetApi open={openApiReset} onClose={handleClickReset} mongoClient={props.mongoClient} refetch={refetch}/>
      <Paper className={classes.paper}>
				{loading || saveLoading || newLoading || deleteLoading ? (<LinearProgress />) : <div style={{height: 4}}></div>}
        <EnhancedTableToolbar numSelected={selected.length} addNewLink={handleAddNewLink} userData={props.userData} resetApi={handleClickReset} mongoClient={props.mongoClient}/>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
									const isItemEditing = isEditing(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
											onClick={(e) => {
												e.stopPropagation();
												e.nativeEvent.stopImmediatePropagation();
											}}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
													onClick={event => handleClick(event, row._id)}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope=	"row" padding="none" className={classes.tableCell}>
                        {isItemEditing ? (
													<FormControl className={classes.formControl}>
										        <InputLabel id={'pane-'+row._id}>Pane</InputLabel>
										        <Select
										          labelId={'pane-'+row._id}
															className={classes.inputField}
										          value={row.pane && row.pane._id ? row.pane._id : ''}
										          onChange={(e) => {
																handleEditLink(row._id, 'pane_id', e.target.value)}}
										        >
															{panes.map((pane, idx) => (
																<MenuItem
																	value={pane._id}
																	key={pane._id}>
																	 {pane && pane.paneTitle ? pane.paneTitle  : ''}
																 </MenuItem>
															))}
										        </Select>
										      </FormControl>
												) : row.pane.paneTitle}
                      </TableCell>
                      <TableCell align="left" padding="none" className={classes.tableCell}>{isItemEditing ? (
												<TextField label="Name"
													value={row.name ? row.name : ''}
													className={classes.inputField}
													onChange={(e) => {
													handleEditLink(row._id, 'name', e.target.value)}}/>
											) : row.name}</TableCell>
                      <TableCell align="left" padding="none" className={classes.tableCell}>{isItemEditing ? (
												<TextField label="Department"
													value={row.department ? row.department : ''}
													className={classes.inputField}
													onChange={(e) => {
													handleEditLink(row._id, 'department', e.target.value)}}/>
											) : row.department}</TableCell>
                      <TableCell align="left" padding="none" className={classes.tableCell}>{isItemEditing ? (
												<TextField label="Location"
													value={row.location ? row.location : ''}
													className={classes.inputField}
													onChange={(e) => {
														handleEditLink(row._id, 'location', e.target.value)}}/>
											) : row.location}</TableCell>
										{(() => {
											if (data.organisation && data.organisation.apiKeys && data.organisation.apiKeys[0] && data.organisation.apiKeys[0].key) {
												return (
													<TableCell
													align="left"
													padding="none"
													onClick={() => {navigator.clipboard.writeText(generateLink(row._id,data.organisation.apiKeys[0].key,false))}}>
														{generateLink(row._id,data.organisation.apiKeys[0].key,true)}
													</TableCell>
												)
											} else {
												return (<TableCell></TableCell>)
											}
										})()}
											<TableCell align="right" padding="none" className={classes.tableCell}>
												<IconButton aria-label="edit" onClick={(e) => {
													handleEditToggle(e, row._id);
													if (isItemEditing && row._id && row._id.length > 10) {
														saveLink({variables: {
															_id: row._id,
															name: row.name,
															department: row.department,
															location: row.location,
															pane_id: row.pane._id
														},
														refetchQueries: [ { query: getLinks }]});
													} else if (isItemEditing) {
														newLink({variables: {
															name: row.name,
															department: row.department,
															location: row.location,
															pane_id: row.pane._id,
															organisation_id: data.organisation._id
														},
														refetchQueries: [ { query: getLinks }]});
													}
												}}>
													{isItemEditing ? (<SaveIcon />) : (<EditIcon />)}
												</IconButton>
												{!isItemEditing ? (
													<ClickAwayListener onClickAway={() => setDeleteTooltip(null)}>
														<div style={{display: 'inline-block'}}>
															<HtmlTooltip
									                PopperProps={{
									                  disablePortal: true,
									                }}
																	interactive
									                onClose={() => setDeleteTooltip(null)}
									                open={deleteTooltip === row._id}
									                disableFocusListener
									                disableHoverListener
									                disableTouchListener
									                title={<React.Fragment>
												            <Typography color="inherit" variant="subtitle2">Delete? <Link href="#" onClick={(e) => {
																				e.preventDefault();
																				deleteLink({variables: {
																					_id: row._id
																				},
																				refetchQueries: [ { query: getLinks }]});
																				setDeleteTooltip(null);
																			}}>Yes</Link></Typography>
												          </React.Fragment>}
									              >
																<IconButton aria-label="delete" onClick={() => setDeleteTooltip(row._id)}>
																	<DeleteIcon />
																</IconButton>
															</HtmlTooltip>
														</div>
													</ClickAwayListener>
												) : (<IconButton aria-label="cancel" onClick={(e) => {
													handleEditToggle(e, row._id);
												}}>
													<CancelIcon />
												</IconButton>)}

											</TableCell>
	                  </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
