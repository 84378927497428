import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { usePaneItems } from '../../Admin/Operations/Pane';
import RenderPane from '../../Admin/Panes/RenderPane/RenderPane';
import ObjectID from "bson-objectid";

const useStyles = makeStyles({
  root: {
    maxWidth: 260,
		padding: 20
  }
});

export default function RenderLink(props) {
  const classes = useStyles();
	const [paneLogo, setPaneLogo] = useState('');

	const { pane } = usePaneItems(props.id);

	function getPaneLogo(id, ext) {
		props.mongoClient.callFunction("getPaneImage", ['discovrfortableau', `pane-logos/${id}.${ext}`]).then(result => {
			if (result) {
				setPaneLogo(`data:image/${ext};base64,${result.toString('base64')}`);
			}
		});
		return '';
	}

	useEffect(() => {
		if (pane) {
			getPaneLogo(ObjectID(pane._id).toHexString(), 'png');
		}/*eslint-disable*/
	}, [pane])/*eslint-enable*/

	return (
		<Grid container spacing={3} className={classes.root}>
			<Grid item xs={12}>
			 <RenderPane pane={pane} paneImage={paneLogo} edit={false} />
      </Grid>
		</Grid>
  );

}
