import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import ObjectID from "bson-objectid";
import Pane from './Pane/Pane';
import EditPane from './EditPane/EditPane';
import { usePaneItems } from '../Operations/Panes';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
	link: {
		textDecoration: 'none'
	}
}));

export default function Panes(props) {
	const { userData } = props;
  const classes = useStyles();

	function getPaneLogo(id, ext) {
		return props.mongoClient.callFunction("getPaneImage", ['discovrfortableau', `pane-logos/${id}.${ext}`]).then(result => {
			return result;
		});
	}

	const { panes, paneActions } = usePaneItems(userData && userData.organisation && userData.organisation._id ? ObjectID(userData.organisation._id) : '');

	function renderPanesGrid() {
		console.log('[Panes.js] Loading panes', panes);
		return (
	    <div className={classes.root}>
				<Grid container spacing={3}>
					{(panes && panes.length > 0 ?  panes : Array.from(new Array(3))).map((pane, idx) => {
						if (pane) {
							return (
				        <Grid item xs key={pane._id}>
									<Link to={'/admin/panes/' + pane._id} className={classes.link}>
				          	<Pane pane={pane} getLogo={getPaneLogo} />
									</Link>
				        </Grid>
							)
						}
						return (
							<Grid item xs key={idx}>
								<Skeleton variant="rect" width={345} height={260} />
								<Box pt={0.5}>
		              <Skeleton width="300px" />
		              <Skeleton width="200px" />
		            </Box>
							</Grid>
						)
					})}
				</Grid>
	    </div>
	  );
	}

	return (
		<BrowserRouter>
			<Route path="/admin/panes" render={renderPanesGrid} exact/>
			<Route path="/admin/panes/:id" children={<EditPane mongoClient={props.mongoClient} userData={userData} panes={panes} paneActions={paneActions}/>} exact/>
		</BrowserRouter>
	)

}
