import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Lock from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
		fontWeight: '400',
		marginLeft: 20,
		fontSize: '1rem'
  },
	textField: {
		width: 400
	}
}));

export default function Login(props) {
	const classes = useStyles();

	const [ creds, setCreds ] = useState({username: '', password: ''});

	if (props.isLoggedIn) {
		window.location.href="/admin"
	}

	return (
		<Container style={{width: 380, marginLeft: 'auto', marginRight: 'auto', paddingTop: 100}}>
		<form className={classes.root} noValidate autoComplete="off">
		<div className={classes.margin}>
			 <Grid container spacing={1} alignItems="flex-end">
				 <Grid item xs={1}>
					 <AlternateEmail />
				 </Grid>
				 <Grid item xs={11}>
					 <TextField
					 id="username"
					 label="Username"
					 value={creds.username || ''}
					 onChange={(e) => setCreds({...creds, username: e.target.value})}
					 className={classes.textField}
					 required />
				 </Grid>
				 <Grid item xs={1}>
					 <Lock />
				 </Grid>
				 <Grid item xs={11}>
					 <TextField
					 id="password"
					 label="Password"
					 value={creds.password || ''}
					 type="password"
					 onChange={(e) => setCreds({...creds, password: e.target.value})}
					 className={classes.textField}
					 required />
				 </Grid>
				 <Grid item xs={12}>
					 <Button variant="contained" color="primary" onClick={() => props.login(creds.username, creds.password)}>
						Login
					 </Button>
				 </Grid>
			 </Grid>
		 </div>
		</form>
		</Container>
	)

}
