import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({

});

export default function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, open, mongoClient } = props;
	const [hours, setHours] = useState(0);

	function handleResetApiKey() {
		mongoClient.callFunction("resetAPIKey", [{hours}]).then(result => {
			if (result.modifiedCount > 0) {
				props.refetch();
				onClose();
			}
		});
	}

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Reset Api Key</DialogTitle>
			<DialogContent>
        <DialogContentText>
          Resetting your API key will invalidate existing links. To give you time to move your users across to the new links you can delay the expiry of the current key.

					To continue set how many hours from now the current key should become invalid.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Expire in Hours"
          type="number"
					onChange={(e) => setHours(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleResetApiKey} color="primary">
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
